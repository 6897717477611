// ENTITY fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOGDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class FormationEventSatisfactionOGDTO {

  idObservableGesture: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  idCriteria: number | null = 0;
  tags: ObservableGestureFlatTag[] | null = [];

  constructor(init?:Partial<FormationEventSatisfactionOGDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOGDTO'] = FormationEventSatisfactionOGDTO;

reverseMapping[FormationEventSatisfactionOGDTO.name] = 'fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOGDTO';

fields['fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOGDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    idCriteria: 'java.lang.Integer',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>'
};
