// fr.hiji.metier.ema.services.front.TeamEMAORServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { EMAObjectiveLiteDTO } from '../entities/EMAObjectiveLiteDTO';
import { EMATeamMemberObjectiveResultDTO } from '../entities/EMATeamMemberObjectiveResultDTO';

@Injectable({
  providedIn: 'root',
})
export class TeamEMAORService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEMAObjective(): RpcRequestBuilder<EMAObjectiveLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamEMAORService.getEMAObjective';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getKpi(idEMAObjective: number, filter: GenericFilter): RpcRequestBuilder<ListWithCount<EMATeamMemberObjectiveResultDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamEMAORService.getKpi';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idEMAObjective, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
