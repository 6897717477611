// ENTITY fr.hiji.metier.teamboard.dto.EMATeamObjectiveDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMATeamResultDTO } from './EMATeamResultDTO';
import { EMAObjectiveType } from './EMAObjectiveType';

export class EMATeamObjectiveDTO {

  idEMAObjective: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  unit: MultilingualString | null = new MultilingualString();
  type: EMAObjectiveType | null = null;
  results: EMATeamResultDTO[] | null = [];

  constructor(init?:Partial<EMATeamObjectiveDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.dto.EMATeamObjectiveDTO'] = EMATeamObjectiveDTO;

reverseMapping[EMATeamObjectiveDTO.name] = 'fr.hiji.metier.teamboard.dto.EMATeamObjectiveDTO';

fields['fr.hiji.metier.teamboard.dto.EMATeamObjectiveDTO']  = {
    idEMAObjective: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    unit: 'com.ic2.sc.MultilingualString',
    type: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveType',
    results: 'java.util.List<fr.hiji.metier.teamboard.dto.EMATeamResultDTO>'
};
