// ENTITY fr.hiji.metier.mif.dto.front.event.FormationEventAcquisitionDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventAcquisitionOGDTO } from './FormationEventAcquisitionOGDTO';
import { FormationEventObservationObservableGesture } from './FormationEventObservationObservableGesture';
import { FormationEventObservation } from './FormationEventObservation';
import { Criteria } from './Criteria';

export class FormationEventAcquisitionDTO {

  oogs: FormationEventObservationObservableGesture[] | null = [];
  ogs: FormationEventAcquisitionOGDTO[] | null = [];
  acquisitionBefore: FormationEventObservation | null = null;
  acquisitionAfter: FormationEventObservation | null = null;
  criterias: Criteria[] | null = [];
  idFormationEvent: number | null = 0;
  startAnsweringDate: Date | null = null;

  constructor(init?:Partial<FormationEventAcquisitionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.event.FormationEventAcquisitionDTO'] = FormationEventAcquisitionDTO;

reverseMapping[FormationEventAcquisitionDTO.name] = 'fr.hiji.metier.mif.dto.front.event.FormationEventAcquisitionDTO';

fields['fr.hiji.metier.mif.dto.front.event.FormationEventAcquisitionDTO']  = {
    oogs: 'java.util.List<fr.hiji.metier.mif.entities.event.observation.FormationEventObservationObservableGesture>',
    ogs: 'java.util.List<fr.hiji.metier.mif.dto.front.event.FormationEventAcquisitionOGDTO>',
    acquisitionBefore: 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservation',
    acquisitionAfter: 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservation',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    idFormationEvent: 'java.lang.Integer',
    startAnsweringDate: 'java.time.Instant'
};
