// ENTITY fr.hiji.metier.fbs.dto.back.FeedbackPerMonthBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FeedbackPerMonthBODTO {

  month: Date | null = null;
  nbFeedback: number | null = 0;
  nbSolicitation: number | null = 0;

  constructor(init?:Partial<FeedbackPerMonthBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.dto.back.FeedbackPerMonthBODTO'] = FeedbackPerMonthBODTO;

reverseMapping[FeedbackPerMonthBODTO.name] = 'fr.hiji.metier.fbs.dto.back.FeedbackPerMonthBODTO';

fields['fr.hiji.metier.fbs.dto.back.FeedbackPerMonthBODTO']  = {
    month: 'java.time.LocalDate',
    nbFeedback: 'java.lang.Integer',
    nbSolicitation: 'java.lang.Integer'
};
