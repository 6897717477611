// ENTITY fr.hiji.metier.user.dto.back.UserBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignLiteDTO } from './CampaignLiteDTO';
import { User } from './User';
import { UserRight } from './UserRight';
import { UserRelationBODTO } from './UserRelationBODTO';
import { SurveyLiteBODTO } from './SurveyLiteBODTO';
import { CertificationWithSessionsDTO } from './CertificationWithSessionsDTO';
import { HijiUserReportSubscriptionBODTO } from './HijiUserReportSubscriptionBODTO';
import { HijiUserReportEmaSubscriptionBODTO } from './HijiUserReportEmaSubscriptionBODTO';
import { InquiryLiteBODTO } from './InquiryLiteBODTO';
import { UserExchangeLiteBODTO } from './UserExchangeLiteBODTO';
import { Population } from './Population';
import { FormationSessionDTO } from './FormationSessionDTO';
import { PDILiteBODTO } from './PDILiteBODTO';
import { HijiUser } from './HijiUser';
import { UserEMALiteBODTO } from './UserEMALiteBODTO';

export class UserBODTO {

  u: User | null = null;
  hu: HijiUser | null = null;
  functionName: string | null = '';
  rights: UserRight[] | null = [];
  idsOrganisations: number[] | null = [];
  reports: HijiUserReportSubscriptionBODTO[] | null = [];
  reportsEma: HijiUserReportEmaSubscriptionBODTO[] | null = [];
  relations: UserRelationBODTO[] | null = [];
  relationsInverse: UserRelationBODTO[] | null = [];
  campaigns: CampaignLiteDTO[] | null = [];
  inquiries: InquiryLiteBODTO[] | null = [];
  surveys: SurveyLiteBODTO[] | null = [];
  pdis: PDILiteBODTO[] | null = [];
  certifications: CertificationWithSessionsDTO[] | null = [];
  populations: Population[] | null = [];
  idsPerimetre: number[] | null = [];
  lastExchanges: UserExchangeLiteBODTO[] | null = [];
  formationSessions: FormationSessionDTO[] | null = [];
  formationSessionsTrainer: FormationSessionDTO[] | null = [];
  lastEMAs: UserEMALiteBODTO[] | null = [];
  emaName: MultilingualString | null = new MultilingualString();
  sendCreateAccountEmail: boolean | null = false;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  autologToken: string | null = '';
  passwordDefined: boolean | null = false;

  constructor(init?:Partial<UserBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.UserBODTO'] = UserBODTO;

reverseMapping[UserBODTO.name] = 'fr.hiji.metier.user.dto.back.UserBODTO';

fields['fr.hiji.metier.user.dto.back.UserBODTO']  = {
    u: 'com.ic2.entity.User',
    hu: 'fr.hiji.metier.user.entities.HijiUser',
    functionName: 'java.lang.String',
    rights: 'java.util.List<com.ic2.entity.UserRight>',
    idsOrganisations: 'java.util.List<java.lang.Integer>',
    reports: 'java.util.List<fr.hiji.metier.user.dto.back.HijiUserReportSubscriptionBODTO>',
    reportsEma: 'java.util.List<fr.hiji.metier.user.dto.back.HijiUserReportEmaSubscriptionBODTO>',
    relations: 'java.util.List<fr.hiji.metier.user.dto.back.UserRelationBODTO>',
    relationsInverse: 'java.util.List<fr.hiji.metier.user.dto.back.UserRelationBODTO>',
    campaigns: 'java.util.List<fr.hiji.metier.campaign.dto.CampaignLiteDTO>',
    inquiries: 'java.util.List<fr.hiji.metier.user.dto.back.InquiryLiteBODTO>',
    surveys: 'java.util.List<fr.hiji.metier.user.dto.back.SurveyLiteBODTO>',
    pdis: 'java.util.List<fr.hiji.metier.user.dto.back.PDILiteBODTO>',
    certifications: 'java.util.List<fr.hiji.metier.certif.dto.front.CertificationWithSessionsDTO>',
    populations: 'java.util.List<fr.hiji.metier.reseau.entities.Population>',
    idsPerimetre: 'java.util.List<java.lang.Integer>',
    lastExchanges: 'java.util.List<fr.hiji.metier.user.dto.back.UserExchangeLiteBODTO>',
    formationSessions: 'java.util.List<fr.hiji.metier.mif.dto.front.FormationSessionDTO>',
    formationSessionsTrainer: 'java.util.List<fr.hiji.metier.mif.dto.front.FormationSessionDTO>',
    lastEMAs: 'java.util.List<fr.hiji.metier.user.dto.back.UserEMALiteBODTO>',
    emaName: 'com.ic2.sc.MultilingualString',
    sendCreateAccountEmail: 'java.lang.Boolean',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    autologToken: 'java.lang.String',
    passwordDefined: 'java.lang.Boolean'
};
