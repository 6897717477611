// ENTITY fr.hiji.metier.ema.dto.back.EMATemplateRepositoryBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMATemplateRepository } from './EMATemplateRepository';
import { Repository } from './Repository';
import { RepositoryHasObservableGesture } from './RepositoryHasObservableGesture';

export class EMATemplateRepositoryBODTO {

  emaTemplateRepository: EMATemplateRepository | null = null;
  repository: Repository | null = null;
  rhogs: RepositoryHasObservableGesture[] | null = [];

  constructor(init?:Partial<EMATemplateRepositoryBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.EMATemplateRepositoryBODTO'] = EMATemplateRepositoryBODTO;

reverseMapping[EMATemplateRepositoryBODTO.name] = 'fr.hiji.metier.ema.dto.back.EMATemplateRepositoryBODTO';

fields['fr.hiji.metier.ema.dto.back.EMATemplateRepositoryBODTO']  = {
    emaTemplateRepository: 'fr.hiji.metier.ema.entities.template.EMATemplateRepository',
    repository: 'fr.hiji.metier.referential.entities.Repository',
    rhogs: 'java.util.List<fr.hiji.metier.referential.entities.RepositoryHasObservableGesture>'
};
