// ENTITY fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizQuestionResultDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { QuestionQCMAnswer } from './QuestionQCMAnswer';
import { QuestionType } from './QuestionType';
import { QuestionFlatTag } from './QuestionFlatTag';

export class ModalityQuizQuestionResultDTO {

  idQuestion: number | null = 0;
  order: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  image: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  explanation: MultilingualString | null = new MultilingualString();
  type: QuestionType | null = null;
  showNumberOfAnswersHint: boolean | null = false;
  allOrNothing: boolean | null = false;
  points: number | null = 0;
  nbOfAnswers: number | null = 0;
  tags: QuestionFlatTag[] | null = [];
  answers: QuestionQCMAnswer[] | null = [];
  deletedFromQuiz: boolean | null = false;

  constructor(init?:Partial<ModalityQuizQuestionResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizQuestionResultDTO'] = ModalityQuizQuestionResultDTO;

reverseMapping[ModalityQuizQuestionResultDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizQuestionResultDTO';

fields['fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizQuestionResultDTO']  = {
    idQuestion: 'java.lang.Integer',
    order: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    image: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    explanation: 'com.ic2.sc.MultilingualString',
    type: 'fr.hiji.metier.referential.entities.quiz.QuestionType',
    showNumberOfAnswersHint: 'java.lang.Boolean',
    allOrNothing: 'java.lang.Boolean',
    points: 'java.lang.Double',
    nbOfAnswers: 'java.lang.Integer',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionFlatTag>',
    answers: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionQCMAnswer>',
    deletedFromQuiz: 'java.lang.Boolean'
};
