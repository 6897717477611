// ENTITY fr.hiji.metier.opinion.entities.OpinionAnswer generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OpinionType } from './OpinionType';

export class OpinionAnswer {

  idOpinionAnswer: number | null = 0;
  idUser: number | null = 0;
  idOpinionQuestion: number | null = 0;
  value: number | null = 0;
  creationDate: Date | null = null;
  opinionType: OpinionType | null = null;
  observed: boolean | null = false;

  constructor(init?:Partial<OpinionAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.entities.OpinionAnswer'] = OpinionAnswer;

reverseMapping[OpinionAnswer.name] = 'fr.hiji.metier.opinion.entities.OpinionAnswer';

fields['fr.hiji.metier.opinion.entities.OpinionAnswer']  = {
    idOpinionAnswer: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    idOpinionQuestion: 'java.lang.Integer',
    value: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    opinionType: 'fr.hiji.metier.opinion.entities.OpinionType',
    observed: 'java.lang.Boolean'
};
