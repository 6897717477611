// ENTITY fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewQuickQuestion generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityInterviewQuickQuestion {

  idModaliteInterviewQuickQuestion: number | null = 0;
  idExam: number | null = 0;
  idCriteria: number | null = 0;
  question: string | null = '';
  observed: boolean | null = false;
  value: number | null = 0;

  constructor(init?:Partial<ModalityInterviewQuickQuestion>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewQuickQuestion'] = ModalityInterviewQuickQuestion;

reverseMapping[ModalityInterviewQuickQuestion.name] = 'fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewQuickQuestion';

fields['fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewQuickQuestion']  = {
    idModaliteInterviewQuickQuestion: 'java.lang.Integer',
    idExam: 'java.lang.Integer',
    idCriteria: 'java.lang.Integer',
    question: 'java.lang.String',
    observed: 'java.lang.Boolean',
    value: 'java.lang.Integer'
};
