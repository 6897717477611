// fr.hiji.metier.verbatim.services.VerbatimsCategoriesBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { OGVerbatimDTO } from '../entities/OGVerbatimDTO';
import { OOGCategoriesDTO } from '../entities/OOGCategoriesDTO';

@Injectable({
  providedIn: 'root',
})
export class VerbatimsCategoriesBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getOgsByCampaign(idCampaign: number): RpcRequestBuilder<OGVerbatimDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VerbatimsCategoriesBOService.getOgsByCampaign';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getVerbatimsByOg(filter: GenericFilter): RpcRequestBuilder<ListWithCount<OOGCategoriesDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VerbatimsCategoriesBOService.getVerbatimsByOg';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  saveOOGCategories(dto: OOGCategoriesDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VerbatimsCategoriesBOService.saveOOGCategories';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.verbatim.dto.OOGCategoriesDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveOOGsCategories(dtos: OOGCategoriesDTO[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VerbatimsCategoriesBOService.saveOOGsCategories';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dtos, 'java.util.List<fr.hiji.metier.verbatim.dto.OOGCategoriesDTO>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
