// fr.hiji.metier.mif.services.front.FormationSessionActionsServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationSessionParticipantAction } from '../entities/FormationSessionParticipantAction';
import { FormationSessionTrainerActionsDTO } from '../entities/FormationSessionTrainerActionsDTO';
import { FormationSessionParticipantObservationsActionsDTO } from '../entities/FormationSessionParticipantObservationsActionsDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionActionsService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  saveParticipantActions10dNotes(dto: FormationSessionParticipantObservationsActionsDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionActionsService.saveParticipantActions10dNotes';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getParticipantActions(idParticipant: number, idFormationSession: number): RpcRequestBuilder<FormationSessionParticipantObservationsActionsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionActionsService.getParticipantActions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO');
  }

  getActionsForTrainer(idFormationSession: number): RpcRequestBuilder<FormationSessionTrainerActionsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionActionsService.getActionsForTrainer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerActionsDTO');
  }

  saveParticipantActions(idFormationSession: number, actions: FormationSessionParticipantAction[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionActionsService.saveParticipantActions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(actions, 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveParticipantActions42dNotes(dto: FormationSessionParticipantObservationsActionsDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionActionsService.saveParticipantActions42dNotes';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveManagerActions45dNotes(dto: FormationSessionParticipantObservationsActionsDTO, idParticipant: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionActionsService.saveManagerActions45dNotes';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
