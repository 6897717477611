// ENTITY fr.hiji.metier.reporting.dto.back.ReportEmaDate generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FrequenceRapport } from './FrequenceRapport';

export class ReportEmaDate {

  date: Date | null = null;
  typeDate: FrequenceRapport | null = null;

  constructor(init?:Partial<ReportEmaDate>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.ReportEmaDate'] = ReportEmaDate;

reverseMapping[ReportEmaDate.name] = 'fr.hiji.metier.reporting.dto.back.ReportEmaDate';

fields['fr.hiji.metier.reporting.dto.back.ReportEmaDate']  = {
    date: 'java.time.LocalDate',
    typeDate: 'fr.hiji.metier.reporting.dto.pdf180.FrequenceRapport'
};
