// ENTITY fr.hiji.metier.campaign.dto.front.ExchangeRepositorySelectionDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ExchangeRepositorySelectionDTO {

  idRepository: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  nbObservation: number | null = 0;
  nbAutoObservation: number | null = 0;

  constructor(init?:Partial<ExchangeRepositorySelectionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ExchangeRepositorySelectionDTO'] = ExchangeRepositorySelectionDTO;

reverseMapping[ExchangeRepositorySelectionDTO.name] = 'fr.hiji.metier.campaign.dto.front.ExchangeRepositorySelectionDTO';

fields['fr.hiji.metier.campaign.dto.front.ExchangeRepositorySelectionDTO']  = {
    idRepository: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    nbObservation: 'java.lang.Integer',
    nbAutoObservation: 'java.lang.Integer'
};
