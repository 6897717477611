// fr.hiji.metier.campaign.services.front.ObservationServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ObservationDTO } from '../entities/ObservationDTO';
import { OpinionDTO } from '../entities/OpinionDTO';

@Injectable({
  providedIn: 'root',
})
export class ObservationService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getObservation(idUser: number, idCampaign: number, idRepository: number): RpcRequestBuilder<ObservationDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservationService.getObservation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front.ObservationDTO');
  }

  saveObservation(observation: ObservationDTO): RpcRequestBuilder<OpinionDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservationService.saveObservation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(observation, 'fr.hiji.metier.campaign.dto.front.ObservationDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.opinion.dto.front.OpinionDTO');
  }

}
