// ENTITY fr.hiji.metier.mif.dto.front.QuizDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationSessionQuizAvailable } from './FormationSessionQuizAvailable';

export class QuizDTO {

  idFormationQuiz: number | null = 0;
  quizName: MultilingualString | null = new MultilingualString();
  formationSessionQuizAvailable: FormationSessionQuizAvailable | null = null;
  done: boolean | null = false;

  constructor(init?:Partial<QuizDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.QuizDTO'] = QuizDTO;

reverseMapping[QuizDTO.name] = 'fr.hiji.metier.mif.dto.front.QuizDTO';

fields['fr.hiji.metier.mif.dto.front.QuizDTO']  = {
    idFormationQuiz: 'java.lang.Integer',
    quizName: 'com.ic2.sc.MultilingualString',
    formationSessionQuizAvailable: 'fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable',
    done: 'java.lang.Boolean'
};
