// DB ENUM fr.hiji.metier.user.dto.front.GroupType generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class GroupType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly GROUP = new GroupType(1, "Groupe", "GROUP");
  static readonly GLOBAL_TEAM = new GroupType(2, "Équipe globale", "GLOBAL_TEAM");
  static readonly DIRECT_TEAM = new GroupType(3, "Équipe directe", "DIRECT_TEAM");

  static readonly values = [
    GroupType.GROUP,
 
    GroupType.GLOBAL_TEAM,
 
    GroupType.DIRECT_TEAM
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of GroupType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of GroupType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.user.dto.front.GroupType'] = GroupType;
reverseMapping[GroupType.name] = 'fr.hiji.metier.user.dto.front.GroupType';
