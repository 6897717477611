// fr.hiji.metier.tools.email.service.back.EmailBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { EmailPreviewDTO } from '../entities/EmailPreviewDTO';
import { EmailBODTO } from '../entities/EmailBODTO';
import { HijiEmail } from '../entities/HijiEmail';

@Injectable({
  providedIn: 'root',
})
export class EmailBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEmail(id: number): RpcRequestBuilder<EmailBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EmailBOService.getEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.tools.email.EmailBODTO');
  }

  editEmail(e: EmailBODTO): RpcRequestBuilder<EmailBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EmailBOService.editEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(e, 'fr.hiji.metier.tools.email.EmailBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.tools.email.EmailBODTO');
  }

  getEmails(filter: GenericFilter): RpcRequestBuilder<ListWithCount<EmailBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EmailBOService.getEmails';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getEmailHiji(email: HijiEmail): RpcRequestBuilder<MultilingualString> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EmailBOService.getEmailHiji';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'fr.hiji.metier.tools.email.HijiEmail', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.sc.MultilingualString');
  }

  previewEmail(email: HijiEmail, subject: string, template: string): RpcRequestBuilder<EmailPreviewDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EmailBOService.previewEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'fr.hiji.metier.tools.email.HijiEmail', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(subject, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(template, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.tools.email.dto.EmailPreviewDTO');
  }

  sendTestEmail(email: HijiEmail, subject: string, template: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EmailBOService.sendTestEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'fr.hiji.metier.tools.email.HijiEmail', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(subject, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(template, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
