// ENTITY fr.hiji.metier.campaign.dto.front.ExchangeItemDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ExchangeItemDTO {

  idExchange: number | null = 0;
  creationDate: Date | null = null;
  idUserObserved: number | null = 0;
  idUserManager: number | null = 0;
  avatarExtensionObserved: string | null = '';
  avatarExtensionManager: string | null = '';
  userManager: string | null = '';
  userObserved: string | null = '';
  repositories: MultilingualString[] | null = [];

  constructor(init?:Partial<ExchangeItemDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ExchangeItemDTO'] = ExchangeItemDTO;

reverseMapping[ExchangeItemDTO.name] = 'fr.hiji.metier.campaign.dto.front.ExchangeItemDTO';

fields['fr.hiji.metier.campaign.dto.front.ExchangeItemDTO']  = {
    idExchange: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserObserved: 'java.lang.Integer',
    idUserManager: 'java.lang.Integer',
    avatarExtensionObserved: 'java.lang.String',
    avatarExtensionManager: 'java.lang.String',
    userManager: 'java.lang.String',
    userObserved: 'java.lang.String',
    repositories: 'java.util.List<com.ic2.sc.MultilingualString>'
};
