// ENTITY fr.hiji.metier.mif.entities.formation.FormationQuiz generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationQuiz {

  idFormationQuiz: number | null = 0;
  idFormation: number | null = 0;
  idQuiz: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<FormationQuiz>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.formation.FormationQuiz'] = FormationQuiz;

reverseMapping[FormationQuiz.name] = 'fr.hiji.metier.mif.entities.formation.FormationQuiz';

fields['fr.hiji.metier.mif.entities.formation.FormationQuiz']  = {
    idFormationQuiz: 'java.lang.Integer',
    idFormation: 'java.lang.Integer',
    idQuiz: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
