// ENTITY fr.hiji.metier.user.dto.back.HijiUserReportEmaSubscriptionBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';

export class HijiUserReportEmaSubscriptionBODTO {

  idOrganisation: number | null = 0;
  organisationName: string | null = '';
  organisationType: OrganisationType | null = null;
  idReportEMAConfig: number | null = 0;
  reportName: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<HijiUserReportEmaSubscriptionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.HijiUserReportEmaSubscriptionBODTO'] = HijiUserReportEmaSubscriptionBODTO;

reverseMapping[HijiUserReportEmaSubscriptionBODTO.name] = 'fr.hiji.metier.user.dto.back.HijiUserReportEmaSubscriptionBODTO';

fields['fr.hiji.metier.user.dto.back.HijiUserReportEmaSubscriptionBODTO']  = {
    idOrganisation: 'java.lang.Integer',
    organisationName: 'java.lang.String',
    organisationType: 'fr.hiji.metier.reseau.entities.OrganisationType',
    idReportEMAConfig: 'java.lang.Integer',
    reportName: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
