// ENTITY fr.hiji.metier.mif.entities.eva.EVAQuizQCMAnswer generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EVAQuizQCMAnswer {

  idFormationSession: number | null = 0;
  idUserParticipant: number | null = 0;
  idQuestion: number | null = 0;
  numAnswer: number | null = 0;
  date: Date | null = null;

  constructor(init?:Partial<EVAQuizQCMAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.eva.EVAQuizQCMAnswer'] = EVAQuizQCMAnswer;

reverseMapping[EVAQuizQCMAnswer.name] = 'fr.hiji.metier.mif.entities.eva.EVAQuizQCMAnswer';

fields['fr.hiji.metier.mif.entities.eva.EVAQuizQCMAnswer']  = {
    idFormationSession: 'java.lang.Integer',
    idUserParticipant: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    numAnswer: 'java.lang.Integer',
    date: 'java.time.Instant'
};
