// ENTITY fr.hiji.metier.user.dto.front.ema.NbExchangePerRepositoryDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class NbExchangePerRepositoryDTO {

  idRepository: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  nbExchange: number | null = 0;

  constructor(init?:Partial<NbExchangePerRepositoryDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.ema.NbExchangePerRepositoryDTO'] = NbExchangePerRepositoryDTO;

reverseMapping[NbExchangePerRepositoryDTO.name] = 'fr.hiji.metier.user.dto.front.ema.NbExchangePerRepositoryDTO';

fields['fr.hiji.metier.user.dto.front.ema.NbExchangePerRepositoryDTO']  = {
    idRepository: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    nbExchange: 'java.lang.Long'
};
