// fr.hiji.metier.mif.services.back.event.stats.FormationEventStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FormationEventStatsInfoBODTO } from '../entities/FormationEventStatsInfoBODTO';
import { FormationEventAcquisitionStatsBODTO } from '../entities/FormationEventAcquisitionStatsBODTO';
import { FormationEventSatisfactionBODTO } from '../entities/FormationEventSatisfactionBODTO';
import { FormationEventAnimationSatisfactionLiteBODTO } from '../entities/FormationEventAnimationSatisfactionLiteBODTO';
import { FormationEventStatsFiltersBODTO } from '../entities/FormationEventStatsFiltersBODTO';

@Injectable({
  providedIn: 'root',
})
export class FormationEventStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getAcquisition(filter: FormationEventStatsFiltersBODTO): RpcRequestBuilder<FormationEventAcquisitionStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.getAcquisition';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventAcquisitionStatsBODTO');
  }

  getVerbatims(filter: FormationEventStatsFiltersBODTO, idObservableGesture: number, nb: number, page: number, idFormationEventAnimation: number, acquisition: boolean): RpcRequestBuilder<ListWithCount<String>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.getVerbatims';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationEventAnimation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getInfos(filter: FormationEventStatsFiltersBODTO): RpcRequestBuilder<FormationEventStatsInfoBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.getInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsInfoBODTO');
  }

  downloadOGCSV(filter: FormationEventStatsFiltersBODTO, idFormationEventAnimation: number, acquisition: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.downloadOGCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationEventAnimation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadVerbatimCSV(filter: FormationEventStatsFiltersBODTO, idObservableGesture: number, idFormationEventAnimation: number, acquisition: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.downloadVerbatimCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationEventAnimation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadSkillCSV(filter: FormationEventStatsFiltersBODTO, idFormationEventAnimation: number, acquisition: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.downloadSkillCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationEventAnimation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getGlobalSatisfaction(filter: FormationEventStatsFiltersBODTO): RpcRequestBuilder<FormationEventSatisfactionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.getGlobalSatisfaction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventSatisfactionBODTO');
  }

  getAnimationsSatisfaction(filter: FormationEventStatsFiltersBODTO, genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationEventAnimationSatisfactionLiteBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.getAnimationsSatisfaction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getAnimationSatisfaction(idFormationEventAnimation: number, filter: FormationEventStatsFiltersBODTO): RpcRequestBuilder<FormationEventSatisfactionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsBOService.getAnimationSatisfaction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationEventAnimation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventSatisfactionBODTO');
  }

}
