// ENTITY fr.hiji.metier.mif.dto.back.event.stats.FormationEventAnimationSatisfactionLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationEventAnimationSatisfactionLiteBODTO {

  idFormationEvent: number | null = 0;
  eventName: MultilingualString | null = new MultilingualString();
  idFormationEventAnimation: number | null = 0;
  animationName: MultilingualString | null = new MultilingualString();
  averageSatisfaction: number | null = null;
  nbAnswers: number | null = 0;
  nbParticipants: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<FormationEventAnimationSatisfactionLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.stats.FormationEventAnimationSatisfactionLiteBODTO'] = FormationEventAnimationSatisfactionLiteBODTO;

reverseMapping[FormationEventAnimationSatisfactionLiteBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventAnimationSatisfactionLiteBODTO';

fields['fr.hiji.metier.mif.dto.back.event.stats.FormationEventAnimationSatisfactionLiteBODTO']  = {
    idFormationEvent: 'java.lang.Integer',
    eventName: 'com.ic2.sc.MultilingualString',
    idFormationEventAnimation: 'java.lang.Integer',
    animationName: 'com.ic2.sc.MultilingualString',
    averageSatisfaction: 'java.lang.Double',
    nbAnswers: 'java.lang.Integer',
    nbParticipants: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
