// ENTITY fr.hiji.metier.certif.dto.back.modality.ModalityLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityType } from './ModalityType';

export class ModalityLiteBODTO {

  idModality: number | null = 0;
  type: ModalityType | null = null;
  code: string | null = '';
  name: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;
  nbQuestions: number | null = 0;

  constructor(init?:Partial<ModalityLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.modality.ModalityLiteBODTO'] = ModalityLiteBODTO;

reverseMapping[ModalityLiteBODTO.name] = 'fr.hiji.metier.certif.dto.back.modality.ModalityLiteBODTO';

fields['fr.hiji.metier.certif.dto.back.modality.ModalityLiteBODTO']  = {
    idModality: 'java.lang.Integer',
    type: 'fr.hiji.metier.certif.entities.modality.ModalityType',
    code: 'java.lang.String',
    name: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean',
    nbQuestions: 'java.lang.Integer'
};
