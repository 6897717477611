// fr.hiji.metier.reseau.services.back.OrganisationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { OrganisationBODTO } from '../entities/OrganisationBODTO';
import { Organisation } from '../entities/Organisation';

@Injectable({
  providedIn: 'root',
})
export class OrganisationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getMembersCSV(idOrganisation: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.getMembersCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrganisation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  organisationNameAlreadyExists(name: string, idOrganisation: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.organisationNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idOrganisation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getOrganisations(): RpcRequestBuilder<OrganisationBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.getOrganisations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getOrganisation(idOrganisation: number): RpcRequestBuilder<OrganisationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.getOrganisation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrganisation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reseau.dto.back.OrganisationBODTO');
  }

  saveOrganisation(organisationBODTO: OrganisationBODTO): RpcRequestBuilder<OrganisationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.saveOrganisation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(organisationBODTO, 'fr.hiji.metier.reseau.dto.back.OrganisationBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reseau.dto.back.OrganisationBODTO');
  }

  updateOrganisations(organisationList: Organisation[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.updateOrganisations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(organisationList, 'java.util.List<fr.hiji.metier.reseau.entities.Organisation>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getRegions(): RpcRequestBuilder<Organisation[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.getRegions';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  deleteAllEmptyOrganisations(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.deleteAllEmptyOrganisations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  findEmptyOrganisationsCount(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.findEmptyOrganisationsCount';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  findEmptyOrganisations(): RpcRequestBuilder<Organisation[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrganisationBOService.findEmptyOrganisations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
