// ENTITY fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationSessionDTO } from './FormationSessionDTO';

export class FormationSessionTrainerDTO {

  idFormation: number | null = 0;
  imageExtension: string | null = '';
  formationName: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  sessions: FormationSessionDTO[] | null = [];

  constructor(init?:Partial<FormationSessionTrainerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerDTO'] = FormationSessionTrainerDTO;

reverseMapping[FormationSessionTrainerDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerDTO']  = {
    idFormation: 'java.lang.Integer',
    imageExtension: 'java.lang.String',
    formationName: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    sessions: 'java.util.List<fr.hiji.metier.mif.dto.front.FormationSessionDTO>'
};
