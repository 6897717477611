// fr.hiji.metier.campaign.services.back.CampaignBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { CampaignBODTO } from '../entities/CampaignBODTO';
import { CampaignItemBODTO } from '../entities/CampaignItemBODTO';
import { RelanceInfoBODTO } from '../entities/RelanceInfoBODTO';

@Injectable({
  providedIn: 'root',
})
export class CampaignBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCampaigns(filter: GenericFilter): RpcRequestBuilder<ListWithCount<CampaignItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.getCampaigns';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getCampaign(idCampaign: number): RpcRequestBuilder<CampaignBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.getCampaign';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.back.CampaignBODTO');
  }

  saveCampaign(c: CampaignBODTO): RpcRequestBuilder<CampaignBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.saveCampaign';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(c, 'fr.hiji.metier.campaign.dto.back.CampaignBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.back.CampaignBODTO');
  }

  getMembersCSV(idCampaign: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.getMembersCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  deployCampaign(idCampaign: number, idUsers: number[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.deployCampaign';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUsers, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getRelanceInfo(idCampaign: number): RpcRequestBuilder<RelanceInfoBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.getRelanceInfo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.back._360.RelanceInfoBODTO');
  }

  relance360(idCampaign: number, relanceInquiryNotDeployed: boolean, relanceInquiryNotAutoObs: boolean, relanceInquiryTargetNoAnswer: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.relance360';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(relanceInquiryNotDeployed, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(relanceInquiryNotAutoObs, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(relanceInquiryTargetNoAnswer, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  campaignNameAlreadyExists(name: MultilingualString, idCampaign: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignBOService.campaignNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

}
