// ENTITY fr.hiji.metier.campaign.entities.pdi.CampaignPDIInfos generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CampaignPDIInfos {

  idCampaign: number | null = 0;
  idPopulation: number | null = 0;
  idRepository: number | null = 0;
  thanksMessage: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<CampaignPDIInfos>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.pdi.CampaignPDIInfos'] = CampaignPDIInfos;

reverseMapping[CampaignPDIInfos.name] = 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIInfos';

fields['fr.hiji.metier.campaign.entities.pdi.CampaignPDIInfos']  = {
    idCampaign: 'java.lang.Integer',
    idPopulation: 'java.lang.Integer',
    idRepository: 'java.lang.Integer',
    thanksMessage: 'com.ic2.sc.MultilingualString'
};
