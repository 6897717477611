// ENTITY fr.hiji.metier.campaign.dto.back.stats.KeyGestureStatDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class KeyGestureStatDTO {

  idObservableGesture: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  nb: number | null = 0;
  avg: number | null = null;
  nbFirstHalf: number | null = 0;
  avgFirstHalf: number | null = null;
  nbSecondHalf: number | null = 0;
  avgSecondHalf: number | null = null;

  constructor(init?:Partial<KeyGestureStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.KeyGestureStatDTO'] = KeyGestureStatDTO;

reverseMapping[KeyGestureStatDTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.KeyGestureStatDTO';

fields['fr.hiji.metier.campaign.dto.back.stats.KeyGestureStatDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbFirstHalf: 'java.lang.Integer',
    avgFirstHalf: 'java.lang.Double',
    nbSecondHalf: 'java.lang.Integer',
    avgSecondHalf: 'java.lang.Double'
};
