// ENTITY fr.hiji.metier.fbs.dto.FeedbackReactionDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FeedbackReaction } from './FeedbackReaction';

export class FeedbackReactionDTO {

  reaction: FeedbackReaction | null = null;
  idUser: number | null = 0;
  avatarExtension: string | null = '';
  firstName: string | null = '';
  lastName: string | null = '';

  constructor(init?:Partial<FeedbackReactionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.dto.FeedbackReactionDTO'] = FeedbackReactionDTO;

reverseMapping[FeedbackReactionDTO.name] = 'fr.hiji.metier.fbs.dto.FeedbackReactionDTO';

fields['fr.hiji.metier.fbs.dto.FeedbackReactionDTO']  = {
    reaction: 'fr.hiji.metier.fbs.entities.FeedbackReaction',
    idUser: 'java.lang.Integer',
    avatarExtension: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String'
};
