// ENTITY fr.hiji.metier.referential.entities.ObservableGestureFlatTag generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ObservableGestureFlatTag {

  idObservableGesture: number | null = 0;
  idTag: number | null = 0;
  assigned: boolean | null = false;
  depth: number | null = 0;

  constructor(init?:Partial<ObservableGestureFlatTag>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.ObservableGestureFlatTag'] = ObservableGestureFlatTag;

reverseMapping[ObservableGestureFlatTag.name] = 'fr.hiji.metier.referential.entities.ObservableGestureFlatTag';

fields['fr.hiji.metier.referential.entities.ObservableGestureFlatTag']  = {
    idObservableGesture: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    assigned: 'java.lang.Boolean',
    depth: 'java.lang.Integer'
};
