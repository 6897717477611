// ENTITY fr.hiji.metier.home.dto.front.SurveyItemDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class SurveyItemDTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  endDate: Date | null = null;
  answered: boolean | null = false;
  draft: boolean | null = false;

  constructor(init?:Partial<SurveyItemDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.SurveyItemDTO'] = SurveyItemDTO;

reverseMapping[SurveyItemDTO.name] = 'fr.hiji.metier.home.dto.front.SurveyItemDTO';

fields['fr.hiji.metier.home.dto.front.SurveyItemDTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    endDate: 'java.time.LocalDate',
    answered: 'java.lang.Boolean',
    draft: 'java.lang.Boolean'
};
