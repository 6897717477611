// ENTITY fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionLiteDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CertificationSessionLiteDTO {

  certificationName: MultilingualString | null = new MultilingualString();
  idCertification: number | null = 0;
  certificationSessionName: string | null = '';
  idCertificationSession: number | null = 0;
  certificationImageExtension: string | null = '';
  certified: boolean | null = false;
  isAssessor: boolean | null = false;
  startDate: Date | null = null;
  endDate: Date | null = null;

  constructor(init?:Partial<CertificationSessionLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionLiteDTO'] = CertificationSessionLiteDTO;

reverseMapping[CertificationSessionLiteDTO.name] = 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionLiteDTO';

fields['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionLiteDTO']  = {
    certificationName: 'com.ic2.sc.MultilingualString',
    idCertification: 'java.lang.Integer',
    certificationSessionName: 'java.lang.String',
    idCertificationSession: 'java.lang.Integer',
    certificationImageExtension: 'java.lang.String',
    certified: 'java.lang.Boolean',
    isAssessor: 'java.lang.Boolean',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate'
};
