// ENTITY fr.hiji.metier.mif.dto.back.formationsession.FormationSessionLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationSessionLiteBODTO {

  idFormationSession: number | null = 0;
  name: string | null = '';
  organisationName: string | null = '';
  date: Date | null = null;
  trainers: string[] | null = [];
  nbParticipant: number | null = 0;
  del: boolean | null = false;
  colorCode: string | null = '';

  constructor(init?:Partial<FormationSessionLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionLiteBODTO'] = FormationSessionLiteBODTO;

reverseMapping[FormationSessionLiteBODTO.name] = 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionLiteBODTO';

fields['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionLiteBODTO']  = {
    idFormationSession: 'java.lang.Integer',
    name: 'java.lang.String',
    organisationName: 'java.lang.String',
    date: 'java.time.LocalDate',
    trainers: 'java.util.List<java.lang.String>',
    nbParticipant: 'java.lang.Integer',
    del: 'java.lang.Boolean',
    colorCode: 'java.lang.String'
};
