// ENTITY fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { TrainingPath } from './TrainingPath';
import { TrainingPathFormationBODTO } from './TrainingPathFormationBODTO';

export class TrainingPathBODTO {

  trainingPath: TrainingPath | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  populationName: MultilingualString | null = new MultilingualString();
  populationDel: boolean | null = false;
  formations: TrainingPathFormationBODTO[] | null = [];

  constructor(init?:Partial<TrainingPathBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathBODTO'] = TrainingPathBODTO;

reverseMapping[TrainingPathBODTO.name] = 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathBODTO';

fields['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathBODTO']  = {
    trainingPath: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPath',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    populationName: 'com.ic2.sc.MultilingualString',
    populationDel: 'java.lang.Boolean',
    formations: 'java.util.List<fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationBODTO>'
};
