// ENTITY fr.hiji.metier.campaign.entities._360.Inquiry generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Inquiry {

  idInquiry: number | null = 0;
  idUser: number | null = 0;
  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  anonymousCollaborator: boolean | null = false;
  anonymousPair: boolean | null = false;
  anonymousManager: boolean | null = false;
  anonymousExternal: boolean | null = false;
  managerAssesor: boolean | null = false;
  maxCollaborator: number | null = 0;
  maxPair: number | null = 0;
  maxManager: number | null = 0;
  maxExternal: number | null = 0;
  minCollaborator: number | null = 0;
  minPair: number | null = 0;
  minManager: number | null = 0;
  minExternal: number | null = 0;
  hideSkill: boolean | null = false;
  randomizeOrder: boolean | null = false;
  updateDate: Date | null = null;
  creationDate: Date | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  deploymentDate: Date | null = null;
  deployed: boolean | null = false;
  actionPlanEnabled: boolean | null = false;
  noAccountMode: boolean | null = false;
  hideResults: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<Inquiry>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities._360.Inquiry'] = Inquiry;

reverseMapping[Inquiry.name] = 'fr.hiji.metier.campaign.entities._360.Inquiry';

fields['fr.hiji.metier.campaign.entities._360.Inquiry']  = {
    idInquiry: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    anonymousCollaborator: 'java.lang.Boolean',
    anonymousPair: 'java.lang.Boolean',
    anonymousManager: 'java.lang.Boolean',
    anonymousExternal: 'java.lang.Boolean',
    managerAssesor: 'java.lang.Boolean',
    maxCollaborator: 'java.lang.Integer',
    maxPair: 'java.lang.Integer',
    maxManager: 'java.lang.Integer',
    maxExternal: 'java.lang.Integer',
    minCollaborator: 'java.lang.Integer',
    minPair: 'java.lang.Integer',
    minManager: 'java.lang.Integer',
    minExternal: 'java.lang.Integer',
    hideSkill: 'java.lang.Boolean',
    randomizeOrder: 'java.lang.Boolean',
    updateDate: 'java.time.Instant',
    creationDate: 'java.time.Instant',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    deploymentDate: 'java.time.Instant',
    deployed: 'java.lang.Boolean',
    actionPlanEnabled: 'java.lang.Boolean',
    noAccountMode: 'java.lang.Boolean',
    hideResults: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
