// ENTITY fr.hiji.metier.ema.entities.EMAUserOG generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAOGAddReason } from './EMAOGAddReason';
import { EMAOGRemoveReason } from './EMAOGRemoveReason';

export class EMAUserOG {

  idUser: number | null = 0;
  idObservableGesture: number | null = 0;
  addReason: EMAOGAddReason | null = null;
  addDate: Date | null = null;
  idUserAdd: number | null = null;
  idEMACreation: number | null = 0;
  idEMAAddedBack: number | null = null;
  gracePeriod: boolean | null = false;
  del: boolean | null = false;
  deleteReason: EMAOGRemoveReason | null = null;
  deleteDate: Date | null = null;
  idUserDelete: number | null = null;
  idEMADeleted: number | null = null;

  constructor(init?:Partial<EMAUserOG>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMAUserOG'] = EMAUserOG;

reverseMapping[EMAUserOG.name] = 'fr.hiji.metier.ema.entities.EMAUserOG';

fields['fr.hiji.metier.ema.entities.EMAUserOG']  = {
    idUser: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    addReason: 'fr.hiji.metier.ema.entities.EMAOGAddReason',
    addDate: 'java.time.Instant',
    idUserAdd: 'java.lang.Integer',
    idEMACreation: 'java.lang.Integer',
    idEMAAddedBack: 'java.lang.Integer',
    gracePeriod: 'java.lang.Boolean',
    del: 'java.lang.Boolean',
    deleteReason: 'fr.hiji.metier.ema.entities.EMAOGRemoveReason',
    deleteDate: 'java.time.Instant',
    idUserDelete: 'java.lang.Integer',
    idEMADeleted: 'java.lang.Integer'
};
