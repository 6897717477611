// ENTITY fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionOpenEndedAnswer generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityQuizQuestionOpenEndedAnswer {

  idExam: number | null = 0;
  idQuestion: number | null = 0;
  answer: string | null = '';
  date: Date | null = null;

  constructor(init?:Partial<ModalityQuizQuestionOpenEndedAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionOpenEndedAnswer'] = ModalityQuizQuestionOpenEndedAnswer;

reverseMapping[ModalityQuizQuestionOpenEndedAnswer.name] = 'fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionOpenEndedAnswer';

fields['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionOpenEndedAnswer']  = {
    idExam: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    answer: 'java.lang.String',
    date: 'java.time.Instant'
};
