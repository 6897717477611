// ENTITY fr.hiji.metier.campaign.entities.pdi.CampaignPDIUser generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CampaignPDIUser {

  idCampaign: number | null = 0;
  idUser: number | null = 0;
  deployed: boolean | null = false;
  deployedDate: Date | null = null;
  mailSent: boolean | null = false;

  constructor(init?:Partial<CampaignPDIUser>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.pdi.CampaignPDIUser'] = CampaignPDIUser;

reverseMapping[CampaignPDIUser.name] = 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIUser';

fields['fr.hiji.metier.campaign.entities.pdi.CampaignPDIUser']  = {
    idCampaign: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    deployed: 'java.lang.Boolean',
    deployedDate: 'java.time.Instant',
    mailSent: 'java.lang.Boolean'
};
