// fr.hiji.metier.campaign.services.front.InquiryServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { InquiryTargetDTO } from '../entities/InquiryTargetDTO';
import { RepositoryLiteDTO } from '../entities/RepositoryLiteDTO';
import { InquiryDTO } from '../entities/InquiryDTO';
import { ObservableGestureLiteDTO } from '../entities/ObservableGestureLiteDTO';
import { InquiryObservationDTO } from '../entities/InquiryObservationDTO';
import { UserLiteDTO } from '../entities/UserLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class InquiryService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getInquiry(idInquiry: number): RpcRequestBuilder<InquiryDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.getInquiry';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front._360.InquiryDTO');
  }

  getInquiryForPdf(token: string, idUser: number, idInquiry: number): RpcRequestBuilder<InquiryDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.getInquiryForPdf';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front._360.InquiryDTO');
  }

  getInquiryPdf(idInquiry: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.getInquiryPdf';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.nio.file.Path');
  }

  getInquiryPdfBack(idInquiry: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.getInquiryPdfBack';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.nio.file.Path');
  }

  saveInquiry(dto: InquiryDTO): RpcRequestBuilder<InquiryDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.saveInquiry';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.campaign.dto.front._360.InquiryDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front._360.InquiryDTO');
  }

  getInquiryObservationById(idInquiry: number): RpcRequestBuilder<InquiryObservationDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.getInquiryObservationById';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front._360.InquiryObservationDTO');
  }

  saveInquiryAnswer(dto: InquiryObservationDTO, draft: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.saveInquiryAnswer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.campaign.dto.front._360.InquiryObservationDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(draft, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  sendReminderEmail(idInquiry: number, target: InquiryTargetDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.sendReminderEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'fr.hiji.metier.campaign.dto.front._360.InquiryTargetDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  createExternalUser(email: string, firstName: string, lastName: string): RpcRequestBuilder<UserLiteDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.createExternalUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.UserLiteDTO');
  }

  getAvailableRepositories(): RpcRequestBuilder<RepositoryLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.getAvailableRepositories';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getObservableGestures(idRepository: number): RpcRequestBuilder<ObservableGestureLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.getObservableGestures';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  resetToDraft(idInquiry: number, idUserObserver: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryService.resetToDraft';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUserObserver, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
