// fr.hiji.metier.mif.services.back.formation.FormationEmailBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationEmail } from '../entities/FormationEmail';

@Injectable({
  providedIn: 'root',
})
export class FormationEmailBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEmails(idFormation: number): RpcRequestBuilder<FormationEmail[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEmailBOService.getEmails';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  saveEmails(idFormation: number, emails: FormationEmail[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEmailBOService.saveEmails';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(emails, 'java.util.List<fr.hiji.metier.mif.entities.formation.FormationEmail>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
