// DB ENUM fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationSessionDatePeriod {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly MATIN = new FormationSessionDatePeriod(1, "Matin", "MATIN");
  static readonly APRES_MIDI = new FormationSessionDatePeriod(2, "Après-midi", "APRES_MIDI");

  static readonly values = [
    FormationSessionDatePeriod.MATIN,
 
    FormationSessionDatePeriod.APRES_MIDI
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationSessionDatePeriod.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationSessionDatePeriod.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod'] = FormationSessionDatePeriod;
reverseMapping[FormationSessionDatePeriod.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod';
