// fr.hiji.metier.campaign.services.back.HHEGImport360BOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { AssessorTeamsHHEGDTO } from '../entities/AssessorTeamsHHEGDTO';

@Injectable({
  providedIn: 'root',
})
export class HHEGImport360BOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  downloadModel(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'HHEGImport360BOService.downloadModel';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  createCampaign(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'HHEGImport360BOService.createCampaign';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  sendMailDeployment(idCampaign: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'HHEGImport360BOService.sendMailDeployment';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getAssessorTeams(idCampaign: number): RpcRequestBuilder<AssessorTeamsHHEGDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'HHEGImport360BOService.getAssessorTeams';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
