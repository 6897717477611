// ENTITY fr.hiji.metier.campaign.dto.front.pdi.PDIResultDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { PDIOGDTO } from './PDIOGDTO';
import { PDIObsDTO } from './PDIObsDTO';
import { Criteria } from './Criteria';
import { ObservationObservableGestures } from './ObservationObservableGestures';
import { RepositoryAccessDTO } from './RepositoryAccessDTO';

export class PDIResultDTO {

  idCampaign: number | null = 0;
  campaignName: MultilingualString | null = new MultilingualString();
  campaignDescription: MultilingualString | null = new MultilingualString();
  repositorie: RepositoryAccessDTO | null = null;
  endDate: Date | null = null;
  startDate: Date | null = null;
  criterias: Criteria[] | null = [];
  observations: PDIObsDTO[] | null = [];
  observableGestures: PDIOGDTO[] | null = [];
  observationObservableGestures: ObservationObservableGestures[] | null = [];

  constructor(init?:Partial<PDIResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.pdi.PDIResultDTO'] = PDIResultDTO;

reverseMapping[PDIResultDTO.name] = 'fr.hiji.metier.campaign.dto.front.pdi.PDIResultDTO';

fields['fr.hiji.metier.campaign.dto.front.pdi.PDIResultDTO']  = {
    idCampaign: 'java.lang.Integer',
    campaignName: 'com.ic2.sc.MultilingualString',
    campaignDescription: 'com.ic2.sc.MultilingualString',
    repositorie: 'fr.hiji.metier.campaign.dto.front.RepositoryAccessDTO',
    endDate: 'java.time.LocalDate',
    startDate: 'java.time.LocalDate',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    observations: 'java.util.List<fr.hiji.metier.campaign.dto.front.pdi.PDIObsDTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.pdi.PDIOGDTO>',
    observationObservableGestures: 'java.util.List<fr.hiji.metier.campaign.entities.ObservationObservableGestures>'
};
