// fr.hiji.metier.mif.services.front.AttendanceServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationSessionParticipantAttendance } from '../entities/FormationSessionParticipantAttendance';
import { FormationSessionDatePeriod } from '../entities/FormationSessionDatePeriod';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  sign(idFormationSession: number, code: string): RpcRequestBuilder<FormationSessionParticipantAttendance> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AttendanceService.sign';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(code, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance');
  }

  accessAllowed(idFormationSession: number, jsTimezoneOffset: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AttendanceService.accessAllowed';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(jsTimezoneOffset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  trainerToggleAttendance(idFormationSession: number, idUser: number, date: Date, period: FormationSessionDatePeriod): RpcRequestBuilder<FormationSessionParticipantAttendance> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AttendanceService.trainerToggleAttendance';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(date, 'java.time.LocalDate', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(period, 'fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance');
  }

}
