// com.ic2.services.UserServiceI generated by ic2 3.0.0-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { User } from '../entities/User';
import { UserImageToken } from '../entities/UserImageToken';
import { UserBundle } from '../entities/UserBundle';
import { UserRelation } from '../entities/UserRelation';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  setPwd(id: number, hashedPwd: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.setPwd';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(hashedPwd, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteUser(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.deleteUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  reactivateUser(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.reactivateUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getUsers(): RpcRequestBuilder<UserBundle[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.getUsers';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  addUser(login: string, email: string, nom: string, prenom: string): RpcRequestBuilder<UserBundle> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.addUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(login, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nom, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(prenom, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.UserBundle');
  }

  updateUser(u: User): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.updateUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(u, 'com.ic2.entity.User', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getUser(idUser: number): RpcRequestBuilder<UserBundle> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.getUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.UserBundle');
  }

  getUserLite(idUser: number): RpcRequestBuilder<User> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.getUserLite';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.User');
  }

  getUsersOwningRight(idRight: number): RpcRequestBuilder<UserBundle[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.getUsersOwningRight';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idRight, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  changeRightForUser(idUser: number, right: number, on: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.changeRightForUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(right, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(on, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteRelation(ur: UserRelation): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.deleteRelation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(ur, 'com.ic2.entity.UserRelation', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  addRelation(idUser: number, typeRelation: number, idUserLie: number): RpcRequestBuilder<UserRelation> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.addRelation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(typeRelation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUserLie, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.UserRelation');
  }

  getAllImageTokens(): RpcRequestBuilder<UserImageToken[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.getAllImageTokens';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  uploadImageFor(idUser: number, idFile: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserService.uploadImageFor';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFile, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
