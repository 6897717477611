// fr.hiji.metier.user.services.common.SSOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { SSOConfigDTO } from '../entities/SSOConfigDTO';
import { UserBundle } from '../entities/UserBundle';

@Injectable({
  providedIn: 'root',
})
export class SSOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  loginSSO(name: string, idToken: string, accessToken: string, origin: string): RpcRequestBuilder<UserBundle> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SSOService.loginSSO';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idToken, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(accessToken, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(origin, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.UserBundle');
  }

  getSSOConfig(name: string): RpcRequestBuilder<SSOConfigDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SSOService.getSSOConfig';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.login.SSOConfigDTO');
  }

  getSSOConfigList(): RpcRequestBuilder<SSOConfigDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SSOService.getSSOConfigList';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
