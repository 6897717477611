// ENTITY fr.hiji.metier.campaign.dto.back._360.UserInquiryBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserInquiryBODTO {

  idInquiry: number | null = 0;
  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  fonction: string | null = '';
  avatarExtension: string | null = '';
  nbTargets: number | null = 0;
  nbAnswers: number | null = 0;
  nbTargetsManager: number | null = 0;
  nbAnswersManager: number | null = 0;
  nbTargetsPair: number | null = 0;
  nbAnswersPair: number | null = 0;
  nbTargetsCollaborateur: number | null = 0;
  nbAnswersCollaborateur: number | null = 0;
  nbTargetsExterne: number | null = 0;
  nbAnswersExterne: number | null = 0;
  userDel: boolean | null = false;
  del: boolean | null = false;
  created: boolean | null = false;
  deployed: boolean | null = false;
  autoObserved: boolean | null = false;
  autoObservationDraft: boolean | null = false;

  constructor(init?:Partial<UserInquiryBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back._360.UserInquiryBODTO'] = UserInquiryBODTO;

reverseMapping[UserInquiryBODTO.name] = 'fr.hiji.metier.campaign.dto.back._360.UserInquiryBODTO';

fields['fr.hiji.metier.campaign.dto.back._360.UserInquiryBODTO']  = {
    idInquiry: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonction: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    nbTargets: 'java.lang.Integer',
    nbAnswers: 'java.lang.Integer',
    nbTargetsManager: 'java.lang.Integer',
    nbAnswersManager: 'java.lang.Integer',
    nbTargetsPair: 'java.lang.Integer',
    nbAnswersPair: 'java.lang.Integer',
    nbTargetsCollaborateur: 'java.lang.Integer',
    nbAnswersCollaborateur: 'java.lang.Integer',
    nbTargetsExterne: 'java.lang.Integer',
    nbAnswersExterne: 'java.lang.Integer',
    userDel: 'java.lang.Boolean',
    del: 'java.lang.Boolean',
    created: 'java.lang.Boolean',
    deployed: 'java.lang.Boolean',
    autoObserved: 'java.lang.Boolean',
    autoObservationDraft: 'java.lang.Boolean'
};
