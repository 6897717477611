// ENTITY fr.hiji.metier.certif.dto.front.modality.ModalitiesDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { ExamLiteDTO } from './ExamLiteDTO';

export class ModalitiesDTO {

  certificationName: MultilingualString | null = new MultilingualString();
  sessionName: string | null = '';
  user: UserFuncAvatarDTO | null = null;
  exams: ExamLiteDTO[] | null = [];
  isAssessor: boolean | null = false;
  hasResults: boolean | null = false;

  constructor(init?:Partial<ModalitiesDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.ModalitiesDTO'] = ModalitiesDTO;

reverseMapping[ModalitiesDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.ModalitiesDTO';

fields['fr.hiji.metier.certif.dto.front.modality.ModalitiesDTO']  = {
    certificationName: 'com.ic2.sc.MultilingualString',
    sessionName: 'java.lang.String',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    exams: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.ExamLiteDTO>',
    isAssessor: 'java.lang.Boolean',
    hasResults: 'java.lang.Boolean'
};
