// ENTITY fr.hiji.metier.ema.dto.front.EMAUserOGDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMAUserOG } from './EMAUserOG';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';
import { EMAOGRepositoryLink } from './EMAOGRepositoryLink';

export class EMAUserOGDTO {

  emaUserOG: EMAUserOG | null = null;
  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  idCriteria: number | null = 0;
  tags: ObservableGestureFlatTag[] | null = [];
  repositories: EMAOGRepositoryLink[] | null = [];
  willBeAddedForNextEMA: boolean | null = false;
  autoDeletedBecauseMasteredAgain: boolean | null = false;
  nbDifferentDayGestureObserved: number | null = 0;
  isComplementary: boolean | null = false;

  constructor(init?:Partial<EMAUserOGDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAUserOGDTO'] = EMAUserOGDTO;

reverseMapping[EMAUserOGDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAUserOGDTO';

fields['fr.hiji.metier.ema.dto.front.EMAUserOGDTO']  = {
    emaUserOG: 'fr.hiji.metier.ema.entities.EMAUserOG',
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    idCriteria: 'java.lang.Integer',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    repositories: 'java.util.List<fr.hiji.metier.ema.dto.front.EMAOGRepositoryLink>',
    willBeAddedForNextEMA: 'java.lang.Boolean',
    autoDeletedBecauseMasteredAgain: 'java.lang.Boolean',
    nbDifferentDayGestureObserved: 'java.lang.Integer',
    isComplementary: 'java.lang.Boolean'
};
