// fr.hiji.metier.referential.services.common.TagServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:30 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { Tag } from '../entities/Tag';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTags(): RpcRequestBuilder<Tag[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TagService.getTags';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
