// ENTITY fr.hiji.metier.ema.dto.back.EMATemplateItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMATemplateItemBODTO {

  idEMATemplate: number | null = 0;
  populationName: MultilingualString | null = new MultilingualString();
  populationDel: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<EMATemplateItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.EMATemplateItemBODTO'] = EMATemplateItemBODTO;

reverseMapping[EMATemplateItemBODTO.name] = 'fr.hiji.metier.ema.dto.back.EMATemplateItemBODTO';

fields['fr.hiji.metier.ema.dto.back.EMATemplateItemBODTO']  = {
    idEMATemplate: 'java.lang.Integer',
    populationName: 'com.ic2.sc.MultilingualString',
    populationDel: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
