// ENTITY fr.hiji.metier.opinion.entities.OpinionSuggestion generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class OpinionSuggestion {

  idOpinionSuggestion: number | null = 0;
  idUser: number | null = 0;
  suggestion: string | null = '';
  creationDate: Date | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<OpinionSuggestion>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.entities.OpinionSuggestion'] = OpinionSuggestion;

reverseMapping[OpinionSuggestion.name] = 'fr.hiji.metier.opinion.entities.OpinionSuggestion';

fields['fr.hiji.metier.opinion.entities.OpinionSuggestion']  = {
    idOpinionSuggestion: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    suggestion: 'java.lang.String',
    creationDate: 'java.time.Instant',
    del: 'java.lang.Boolean'
};
