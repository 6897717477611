// ENTITY fr.hiji.metier.referential.entities.RepositoryHasObservableGesture generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { HighlightType } from './HighlightType';

export class RepositoryHasObservableGesture {

  idRepository: number | null = 0;
  idObservableGesture: number | null = 0;
  order: number | null = 0;
  highlight: HighlightType | null = null;

  constructor(init?:Partial<RepositoryHasObservableGesture>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.RepositoryHasObservableGesture'] = RepositoryHasObservableGesture;

reverseMapping[RepositoryHasObservableGesture.name] = 'fr.hiji.metier.referential.entities.RepositoryHasObservableGesture';

fields['fr.hiji.metier.referential.entities.RepositoryHasObservableGesture']  = {
    idRepository: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    order: 'java.lang.Integer',
    highlight: 'fr.hiji.metier.referential.entities.HighlightType'
};
