// fr.hiji.metier.certif.services.back.CertificationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { CertificationBODTO } from '../entities/CertificationBODTO';

@Injectable({
  providedIn: 'root',
})
export class CertificationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCertifications(filter: GenericFilter): RpcRequestBuilder<ListWithCount<CertificationBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationBOService.getCertifications';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getCertification(id: number): RpcRequestBuilder<CertificationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationBOService.getCertification';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.CertificationBODTO');
  }

  saveCertification(dto: CertificationBODTO): RpcRequestBuilder<CertificationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationBOService.saveCertification';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.certif.dto.back.CertificationBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.CertificationBODTO');
  }

}
