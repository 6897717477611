// ENTITY fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityListStatsBODTO } from './ModalityListStatsBODTO';

export class CertificationStatsModalityBODTO {

  modality: ModalityListStatsBODTO[] | null = [];
  nbParticipant: number | null = 0;
  nbExamen: number | null = 0;
  nbSuccess: number | null = 0;
  nbEchec: number | null = 0;

  constructor(init?:Partial<CertificationStatsModalityBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityBODTO'] = CertificationStatsModalityBODTO;

reverseMapping[CertificationStatsModalityBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityBODTO']  = {
    modality: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.ModalityListStatsBODTO>',
    nbParticipant: 'java.lang.Integer',
    nbExamen: 'java.lang.Integer',
    nbSuccess: 'java.lang.Integer',
    nbEchec: 'java.lang.Integer'
};
