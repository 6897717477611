// ENTITY fr.hiji.metier.certif.dto.front.CertificationResultDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { CertificationCandidateResult } from './CertificationCandidateResult';
import { CertificationExamResultDTO } from './CertificationExamResultDTO';

export class CertificationResultDTO {

  user: UserFuncAvatarDTO | null = null;
  certificationName: MultilingualString | null = new MultilingualString();
  isAssessor: boolean | null = false;
  exams: CertificationExamResultDTO[] | null = [];
  certifs: CertificationCandidateResult[] | null = [];

  constructor(init?:Partial<CertificationResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.CertificationResultDTO'] = CertificationResultDTO;

reverseMapping[CertificationResultDTO.name] = 'fr.hiji.metier.certif.dto.front.CertificationResultDTO';

fields['fr.hiji.metier.certif.dto.front.CertificationResultDTO']  = {
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    certificationName: 'com.ic2.sc.MultilingualString',
    isAssessor: 'java.lang.Boolean',
    exams: 'java.util.List<fr.hiji.metier.certif.dto.front.result.CertificationExamResultDTO>',
    certifs: 'java.util.List<fr.hiji.metier.certif.entities.CertificationCandidateResult>'
};
