// ENTITY fr.hiji.metier.bilan.entities.Bilan generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Bilan {

  idBilan: number | null = 0;
  idUser: number | null = 0;
  releaseDate: Date | null = null;
  comiteDate: Date | null = null;
  improvement: string | null = '';
  talents: string | null = '';
  synthese: string | null = '';
  rating: string | null = '';
  grade: string | null = '';
  potentiel: string | null = '';
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<Bilan>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.bilan.entities.Bilan'] = Bilan;

reverseMapping[Bilan.name] = 'fr.hiji.metier.bilan.entities.Bilan';

fields['fr.hiji.metier.bilan.entities.Bilan']  = {
    idBilan: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    releaseDate: 'java.time.LocalDate',
    comiteDate: 'java.time.LocalDate',
    improvement: 'java.lang.String',
    talents: 'java.lang.String',
    synthese: 'java.lang.String',
    rating: 'java.lang.String',
    grade: 'java.lang.String',
    potentiel: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
