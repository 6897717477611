// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionDatePeriod } from './FormationSessionDatePeriod';

export class FormationSessionParticipantAttendance {

  idFormationSession: number | null = 0;
  idUser: number | null = 0;
  date: Date | null = null;
  period: FormationSessionDatePeriod | null = null;
  idUserSign: number | null = 0;
  signDate: Date | null = null;

  constructor(init?:Partial<FormationSessionParticipantAttendance>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance'] = FormationSessionParticipantAttendance;

reverseMapping[FormationSessionParticipantAttendance.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance';

fields['fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance']  = {
    idFormationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    date: 'java.time.LocalDate',
    period: 'fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod',
    idUserSign: 'java.lang.Integer',
    signDate: 'java.time.Instant'
};
