// ENTITY fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypePerAnswerValBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OpinionType } from './OpinionType';

export class NbAnswerPerOpinionTypePerAnswerValBODTO {

  opinionType: OpinionType | null = null;
  answerValue: number | null = 0;
  nbAnswers: number | null = 0;

  constructor(init?:Partial<NbAnswerPerOpinionTypePerAnswerValBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypePerAnswerValBODTO'] = NbAnswerPerOpinionTypePerAnswerValBODTO;

reverseMapping[NbAnswerPerOpinionTypePerAnswerValBODTO.name] = 'fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypePerAnswerValBODTO';

fields['fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypePerAnswerValBODTO']  = {
    opinionType: 'fr.hiji.metier.opinion.entities.OpinionType',
    answerValue: 'java.lang.Integer',
    nbAnswers: 'java.lang.Integer'
};
