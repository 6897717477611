// ENTITY fr.hiji.metier.referential.dto.front.ObservableGestureLiteDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class ObservableGestureLiteDTO {

  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  tags: ObservableGestureFlatTag[] | null = [];

  constructor(init?:Partial<ObservableGestureLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.front.ObservableGestureLiteDTO'] = ObservableGestureLiteDTO;

reverseMapping[ObservableGestureLiteDTO.name] = 'fr.hiji.metier.referential.dto.front.ObservableGestureLiteDTO';

fields['fr.hiji.metier.referential.dto.front.ObservableGestureLiteDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>'
};
