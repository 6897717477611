// fr.hiji.metier.user.services.back.UserFonctionPerimeterConfigurationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:29 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UserFonctionPerimeterConfig } from '../entities/UserFonctionPerimeterConfig';

@Injectable({
  providedIn: 'root',
})
export class UserFonctionPerimeterConfigurationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  saveCampaignSurveyPerimeterOrganisation(userFonctionPerimeterConfigs: UserFonctionPerimeterConfig[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserFonctionPerimeterConfigurationBOService.saveCampaignSurveyPerimeterOrganisation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(userFonctionPerimeterConfigs, 'java.util.List<fr.hiji.metier.user.entities.UserFonctionPerimeterConfig>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getCampaignSurveyPerimeterOrganisations(): RpcRequestBuilder<UserFonctionPerimeterConfig[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserFonctionPerimeterConfigurationBOService.getCampaignSurveyPerimeterOrganisations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  applyPerimeters(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserFonctionPerimeterConfigurationBOService.applyPerimeters';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  deleteAllPerimeters(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserFonctionPerimeterConfigurationBOService.deleteAllPerimeters';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  deleteConcernedPerimeters(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserFonctionPerimeterConfigurationBOService.deleteConcernedPerimeters';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
