// ENTITY fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidatesDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CertificationSessionCandidateDTO } from './CertificationSessionCandidateDTO';
import { ModalityLiteDTO } from './ModalityLiteDTO';

export class CertificationSessionCandidatesDTO {

  idCertification: number | null = 0;
  certificationImageExtension: string | null = '';
  certificationName: MultilingualString | null = new MultilingualString();
  sessionName: string | null = '';
  code: string | null = '';
  candidates: CertificationSessionCandidateDTO[] | null = [];
  modalitiesWithAccessGrant: ModalityLiteDTO[] | null = [];

  constructor(init?:Partial<CertificationSessionCandidatesDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidatesDTO'] = CertificationSessionCandidatesDTO;

reverseMapping[CertificationSessionCandidatesDTO.name] = 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidatesDTO';

fields['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidatesDTO']  = {
    idCertification: 'java.lang.Integer',
    certificationImageExtension: 'java.lang.String',
    certificationName: 'com.ic2.sc.MultilingualString',
    sessionName: 'java.lang.String',
    code: 'java.lang.String',
    candidates: 'java.util.List<fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidateDTO>',
    modalitiesWithAccessGrant: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.ModalityLiteDTO>'
};
