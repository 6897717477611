// ENTITY fr.hiji.metier.visualize.dto.front.VizBilanDTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:37 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class VizBilanDTO {

  idBilan: number | null = 0;
  comiteDate: Date | null = null;

  constructor(init?:Partial<VizBilanDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.visualize.dto.front.VizBilanDTO'] = VizBilanDTO;

reverseMapping[VizBilanDTO.name] = 'fr.hiji.metier.visualize.dto.front.VizBilanDTO';

fields['fr.hiji.metier.visualize.dto.front.VizBilanDTO']  = {
    idBilan: 'java.lang.Integer',
    comiteDate: 'java.time.LocalDate'
};
