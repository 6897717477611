// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventAnimationTrainerBODTO generated by ic2 0.0.1-SNAPSHOT at Fri Sep 06 13:29:38 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventAnimationTrainer } from './FormationEventAnimationTrainer';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class FormationEventAnimationTrainerBODTO {

  formationEventAnimationTrainer: FormationEventAnimationTrainer | null = null;
  userFuncAvatarDTO: UserFuncAvatarDTO | null = null;

  constructor(init?:Partial<FormationEventAnimationTrainerBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventAnimationTrainerBODTO'] = FormationEventAnimationTrainerBODTO;

reverseMapping[FormationEventAnimationTrainerBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventAnimationTrainerBODTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventAnimationTrainerBODTO']  = {
    formationEventAnimationTrainer: 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationTrainer',
    userFuncAvatarDTO: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO'
};
